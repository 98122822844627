<template>
  <div>
    <AutoSlider></AutoSlider>
    <!-- ===== -->
    <div class="how_to_play">
      <img src="@/assets/how-to-play.png" />
    </div>
    <!-- ===== -->
    <main class="mian_card_box">
      <router-link to="/sport" class="card_box">
        <img src="@/assets/sport-category.png" />
        <p class="card_box_button">កីឡា</p>
      </router-link>
      <router-link to="/casino" class="card_box">
        <img src="@/assets/casino-category.png" />
        <p class="card_box_button">កាស៊ីណូ</p>
      </router-link>
      <router-link to="/cockfighter" class="card_box">
        <img src="@/assets/cockfight-gallery.png" />
        <p class="card_box_button">ជល់មាន់</p>
      </router-link>
      <router-link to="/slot" class="card_box">
        <img src="@/assets/slot-gallery.png" />
        <p class="card_box_button">ហ្កេមស្លុត</p>
      </router-link>
    </main>
    <!-- ===== -->
    <div class="payment_container">
      <img src="@/assets/banner-banking-1.jpg" />
    </div>
    <!-- ===== -->
  </div>
</template>

<script>
import AutoSlider from "@/components/AutoSlide.vue";
export default {
  name: "HomeView",
  components: {
    AutoSlider,
  },
};
</script>

<style>
.how_to_play {
  max-width: 1160px !important;
  margin: 20px 0px;
}
.how_to_play > img {
  width: 100%;
}
/* ===== */
.mian_card_box {
  max-width: 1150px !important;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px;
}
@media screen and (max-width: 800px) {
  .mian_card_box {
    grid-template-columns: 1fr 1fr;
  }
}
.card_box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  background: #ffffff;
  border: 2px solid #ffdd31;
  text-decoration: none;
}
.card_box > img {
  width: 100%;
  font-family: "Koulen", cursive;
}
.card_box_button {
  font-size: 18px;
  color: #13060d;
  padding: 10px 0px;
  border-top: 2px solid #ffdd31;
  background: #ffdd31;
  font-family: "Koulen", cursive;
  margin: 0px;
}
.card_box:hover .card_box_button {
  background: #13060d;
  color: #ffffff;
}
/* ===== */
.payment_container {
  max-width: 1150px !important;
  margin: 20px 0px;
}
.payment_container > img {
  width: 100%;
  height: fit-content;
}
</style>
