<template>
  <!-- Main Desktop View -->
  <main class="desktop_view hide_on_small">
    <div class="desktop_header_container">
      <div class="desktop_header">
        <div class="desktop_header_top">
          <div class="desktop_header_left">
            <router-link to="/" class="desktop_header_left_logo > img">
              <img src="@/assets/kh-mobile-logo.png" />
            </router-link>
          </div>
          <div class="desktop_header_right">
            <img src="@/assets/contact-top.png" />
            <div class="fb_telegram_top">
              <a
                href="https://www.facebook.com/lucky.winner.714655"
                target="_blank"
              >
                <img src="@/assets/fb-top.png" />
              </a>
              <a href="https://t.me/lucky2winner" target="_blank">
                <img src="@/assets/telegram-top.png" />
              </a>
            </div>
          </div>
        </div>
        <nav class="desktop_header_nav">
          <router-link to="/" class="desktop_nav_box">
            <a class="desktop_nav_menu">
              <img src="@/assets/home.png" />
              <p>ទំព័រដើម</p>
            </a>
            <div class="indicator">
              <span></span>
            </div>
          </router-link>
          <router-link to="/sport" class="desktop_nav_box">
            <a class="desktop_nav_menu">
              <img src="@/assets/sport.png" />
              <p>កីឡា</p>
            </a>
            <div class="indicator">
              <span></span>
            </div>
          </router-link>
          <router-link to="/casino" class="desktop_nav_box">
            <a class="desktop_nav_menu">
              <img src="@/assets/casino.png" />
              <p>កាសុីណូផ្សាយផ្ទាល់</p>
            </a>
            <div class="indicator">
              <span></span>
            </div>
          </router-link>
          <router-link to="/cockfighter" class="desktop_nav_box">
            <a class="desktop_nav_menu">
              <img src="@/assets/cockfight.png" />
              <p>ជល់មាន់</p>
            </a>
            <div class="indicator">
              <span></span>
            </div>
          </router-link>
          <router-link to="/slot" class="desktop_nav_box">
            <a class="desktop_nav_menu">
              <img src="@/assets/slot.png" />
              <p>ស្លុតហ្កេម</p>
            </a>
            <div class="indicator">
              <span></span>
            </div>
          </router-link>
          <router-link to="/fish" class="desktop_nav_box">
            <a class="desktop_nav_menu">
              <img src="@/assets/fish.png" />
              <p>បាញ់ត្រី</p>
            </a>
            <div class="indicator">
              <span></span>
            </div>
          </router-link>
          <router-link to="/specialoffer" class="desktop_nav_box">
            <a class="desktop_nav_menu">
              <img src="@/assets/offer.png" />
              <p>ការផ្ដល់ជូនពិសេស</p>
            </a>
            <div class="indicator">
              <span></span>
            </div>
          </router-link>
          <router-link to="/about" class="desktop_nav_box">
            <a class="desktop_nav_menu">
              <img src="@/assets/about.png" />
              <p>អំពីយើង</p>
            </a>
            <div class="indicator">
              <span></span>
            </div>
          </router-link>
          <router-link to="/contact" class="desktop_nav_box">
            <a class="desktop_nav_menu">
              <img src="@/assets/contact.png" />
              <p>ទំនាក់ទំនង</p>
            </a>
            <div class="indicator">
              <span></span>
            </div>
          </router-link>
        </nav>
      </div>
    </div>
    <main class="main_body_container">
      <div class="body_container">
        <router-view class="content_container"></router-view>
        <FooterView></FooterView>
      </div>
    </main>
  </main>

  <!-- Main Mobile View -->
  <main class="mobile_view hide_on_large">
    <!-- Offcanvas Side bar Menu -->
    <div class="mobile_side_menu" :class="{ pushMobileSideMenu: isActive }">
      <nav class="menu_list">
        <h5>Menu</h5>
        <router-link to="/" class="list_menu" v-on:click="isActive = !isActive">
          <img src="@/assets/home.png" />
          <p class="menu_title">ទំព័រដើម</p>
        </router-link>
        <router-link
          to="/sport"
          class="list_menu"
          v-on:click="isActive = !isActive"
        >
          <img src="@/assets/sport.png" />
          <p class="menu_title">កីឡា</p>
        </router-link>
        <router-link
          to="/casino"
          class="list_menu"
          v-on:click="isActive = !isActive"
        >
          <img src="@/assets/casino.png" />
          <p class="menu_title">កាសុីណូផ្សាយផ្ទាល់</p>
        </router-link>
        <router-link
          to="/cockfighter"
          class="list_menu"
          v-on:click="isActive = !isActive"
        >
          <img src="@/assets/cockfight.png" />
          <p class="menu_title">ជល់មាន់</p>
        </router-link>
        <router-link
          to="/slot"
          class="list_menu"
          v-on:click="isActive = !isActive"
        >
          <img src="@/assets/slot.png" />
          <p class="menu_title">ស្លុតហ្កេម</p>
        </router-link>
        <router-link
          to="/fish"
          class="list_menu"
          v-on:click="isActive = !isActive"
        >
          <img src="@/assets/fish.png" />
          <p class="menu_title">បាញ់ត្រី</p>
        </router-link>
        <router-link
          to="/specialoffer"
          class="list_menu"
          v-on:click="isActive = !isActive"
        >
          <img src="@/assets/offer.png" />
          <p class="menu_title">ការផ្ដល់ជូនពិសេស</p>
        </router-link>
        <router-link
          to="/about"
          class="list_menu"
          v-on:click="isActive = !isActive"
        >
          <img src="@/assets/about.png" />
          <p class="menu_title">អំពីយើង</p>
        </router-link>
        <router-link
          to="/contact"
          class="list_menu"
          v-on:click="isActive = !isActive"
        >
          <img src="@/assets/contact.png" />
          <p class="menu_title">ទំនាក់ទំនង</p>
        </router-link>
      </nav>
      <div class="free_area" v-on:click="isActive = !isActive"></div>
    </div>
    <!-- Main Content View -->
    <div class="mobile_content_view" :class="{ pushMobileContent: isActive }">
      <div class="mobile_header">
        <div class="mobile_left_side">
          <div class="topbar_menu_btn" v-on:click="isActive = !isActive">
            <img src="@/assets/menu-white.png" />
          </div>
          <router-link to="/" class="topbar_logo">
            <img src="@/assets/kh-mobile-logo.png" />
          </router-link>
        </div>
        <div class="mobile_right_side">
          <a href="" target="_blank">
            <img src="@/assets/fb-top.png" />
          </a>
          <a href="" target="_blank">
            <img src="@/assets/telegram-top.png" />
          </a>
        </div>
      </div>
      <div class="mobile_content_body">
        <router-view></router-view>
        <FooterView></FooterView>
      </div>
    </div>
  </main>
</template>

<script>
import FooterView from "@/components/FooterView.vue";
export default {
  name: "LayoutView",
  components: {
    FooterView,
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

/* ===== Desktop Header ===== */
.desktop_view {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}
.desktop_header_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  background: #eeeeee;
  border-bottom: 2px solid #bbbbbb;
}
.desktop_header {
  width: 1150px;
  display: grid;
  grid-template-rows: auto auto;
}
.desktop_header_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.desktop_header_left {
  width: 100%;
  margin-left: 50px;
  text-align: start;
}
.desktop_header_left_logo > img {
  height: 100px;
}
.desktop_header_right {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 10px;
}
.fb_telegram_top {
  display: grid;
  grid-template-rows: auto auto;
}
.fb_telegram_top > a {
  margin: 5px 0px;
}
.desktop_header_nav {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 2px;
  background: rgb(19, 6, 13);
  background: linear-gradient(
    0deg,
    rgba(19, 6, 13, 1) 0%,
    rgba(47, 12, 31, 1) 50%,
    rgba(19, 6, 13, 1) 100%
  );
}
.desktop_nav_box {
  display: grid;
  grid-template-rows: auto auto;
  text-decoration: none;
  padding: 2px 0px 3px 0px;
}

.indicator {
  width: 100%;
  display: flex;
  justify-content: center;
}

.indicator > span {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.desktop_nav_menu {
  width: fit-content;
  display: flex;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  padding: 8px 15px 0px 10px;
}

.desktop_nav_box:hover .desktop_nav_menu > p {
  color: #ffdd31;
}

.desktop_nav_box:hover .indicator > span {
  border-bottom: 10px solid red;
}

.desktop_nav_menu > p {
  padding-top: 5px;
  padding-left: 6px;
  margin: 0px;
  font-family: "Battambang", cursive !important;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14;
}

.desktop_nav_menu > img {
  height: 20px;
}
/* ===== End Desktop Header ===== */

/* ===== Desktop body ===== */
.main_body_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.body_container {
  width: 1150px;
}
/* ===== End Desktop body ===== */

/* ===== Mobile Layout ===== */
.mobile_view {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.mobile_side_menu {
  width: 0px;
  height: 100vh;
  position: absolute;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  z-index: 999999;
  overflow: hidden;
  transition: 0.8s;
}
.pushMobileSideMenu {
  width: 100vw;
}
.menu_list {
  width: 300px;
  text-align: start;
  background: #13060d;
  font-family: "Battambang", cursive;
  border-right: 1px solid #ffffff;
  padding: 15px;
  overflow: hidden;
}
.menu_list > h5 {
  color: #ffdd31;
  margin-bottom: 25px;
}
.list_menu {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  text-decoration: none;
}
.list_menu > img {
  height: 25px;
}
.menu_title {
  font-size: 16px;
  margin-top: 5px;
  color: #ffdd31;
  padding-left: 8px;
}
.list_menu .menu_title:hover {
  color: #ff0000;
}
.free_area {
  background: transparent;
}
.mobile_content_view {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  margin-left: 0px;
  transition: 0.5s;
}
.pushMobileContent {
  margin-left: 300px;
}
.mobile_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  padding: 2px 0px;
}
.mobile_left_side {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 10px;
}

.topbar_menu_btn {
  height: fit-content;
  padding: 4px 9px;
  background: #13060d;
  border-radius: 5px;
  cursor: pointer;
}
.topbar_menu_btn > img {
  height: 25px;
}
.topbar_logo {
  margin-left: 20px;
}
.topbar_logo > img {
  height: 50px;
}
.mobile_right_side {
  display: flex;
  justify-content: start;
}
.mobile_right_side > a > img {
  height: 28px;
  margin: 5px 2px;
}
.mobile_content_body {
  width: 100vw;
  height: 100%;
  overflow: scroll !important;
}
/* ===== End Mobile Layout ===== */

@media screen and (max-width: 990px) {
}

@media screen and (max-width: 1154px) {
  .hide_on_small {
    display: none !important;
  }
}

@media screen and (min-width: 1155px) {
  .hide_on_large {
    display: none !important;
  }
}
</style>
