import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/sport",
    name: "sport",
    component: () => import("../views/SportView.vue"),
  },
  {
    path: "/casino",
    name: "casino",
    component: () => import("../views/CasinoView.vue"),
  },
  {
    path: "/cockfighter",
    name: "cockfighter",
    component: () => import("../views/CockFighterView.vue"),
  },
  {
    path: "/slot",
    name: "slot",
    component: () => import("../views/SlotView.vue"),
  },
  {
    path: "/fish",
    name: "fish",
    component: () => import("../views/FishView.vue"),
  },
  {
    path: "/specialoffer",
    name: "specialoffer",
    component: () => import("../views/OfferView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
