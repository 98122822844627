<template>
  <main class="main_footer">
    <div class="footer_top_container">
      <div class="left_side_footer">
        <p>មធ្យោបាយទូទាត់ប្រាក់:</p>
        <img src="@/assets/foot-banking.png" />
      </div>
      <div class="right_side_footer hide_on_small">
        <p>
          <i class="bi bi-telephone-fill box_phone"></i> ទំនាក់ទំនង
          សេវាបំរើអតិថិជន
        </p>
        <p><i class="bi bi-telephone-fill"></i> 078-908-987 ឬ 069-450-903</p>
        <p><i class="bi bi-telephone-fill"></i> 097-679-6527</p>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="footer_two_column">
        <img src="@/assets/footer-banner.png" />
        <p>Copyright © 2023 lucky2winner.com | All Rights Reserved</p>
      </div>
    </div>
  </main>
</template>

<style>
.main_footer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  background: #13060d;
}
.footer_top_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: "Battambang", cursive;
}
.left_side_footer {
  text-align: start;
  padding: 15px;
}
.left_side_footer > img {
  width: 100%;
}
.right_side_footer {
  text-align: start;
  padding: 15px;
  margin-right: 40px;
}
.right_side_footer > p {
  margin: 8px 0px;
}
.box_phone {
  font-size: 14px;
  background: #ffffff;
  color: #13060d;
  margin: 0px;
  padding: 0px 5px;
  border-radius: 5px;
}
.footer_bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  font-family: "Battambang", cursive;
  font-size: 14px;
  border-top: 2px solid #ffdd31;
}
.footer_two_column {
  width: 100%;
}
.footer_two_column > img {
  width: 100%;
}
.footer_two_column > p {
  font-size: 14px;
}
</style>
