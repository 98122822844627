<template>
  <LayoutView></LayoutView>
</template>

<script>
import LayoutView from "@/views/Layout.vue";
export default {
  components: {
    LayoutView,
  },
};
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
}
body {
  background-image: url("@/assets/body-bg.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #370617;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
