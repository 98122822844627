<template>
  <div
    id="carouselExampleAutoplaying"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="@/assets/slide01.jpg" class="d-block w-100" />
      </div>
      <div class="carousel-item">
        <img src="@/assets/slide02.jpg" class="d-block w-100" />
      </div>
      <div class="carousel-item">
        <img src="@/assets/slide03.jpg" class="d-block w-100" />
      </div>
      <div class="carousel-item">
        <img src="@/assets/slide04.jpg" class="d-block w-100" />
      </div>
      <div class="carousel-item">
        <img src="@/assets/slide05.jpg" class="d-block w-100" />
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleAutoplaying"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleAutoplaying"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script></script>

<style>
.carousel {
  max-width: 1150px !important;
  border: 1px solid #ffdd31;
  margin-top: 5px;
}
.prev_icon {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 5px;
}
.prev_icon > span {
  margin-top: 5px;
  margin-right: 3px;
}
.next_icon {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 5px;
}
.next_icon > span {
  margin-top: 5px;
  margin-left: 3px;
}
</style>
